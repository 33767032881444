import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-governance',
  templateUrl: './about-governance.component.html',
  styleUrls: ['./about-governance.component.css']
})
export class AboutGovernanceComponent implements OnInit {

  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/governance_00_2021072010.jpg' : 'assets/images/about/Sustainable/governance_00_2021072010.jpg';

  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
