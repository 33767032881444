import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-about-goals',
  templateUrl: './about-goals.component.html',
  styleUrls: ['./about-goals.component.css']
})
export class AboutGoalsComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_00_2021071901.png' : 'assets/images/about/Sustainable/Sustainable_00_2021071901.png';
  image_mo = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_mo_00_2021071901.png' : 'assets/images/about/Sustainable/Sustainable_mo_00_2021071901.png';
  image_1 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_01_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_01_2021071901.jpg';
  image_2 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_02_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_02_2021071901.jpg';
  image_3 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_03_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_03_2021071901.jpg';
  image_4 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_04_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_04_2021071901.jpg';
  image_5 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_05_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_05_2021071901.jpg';
  image_6 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_06_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_06_2021071901.jpg';
  image_7 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_07_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_07_2021071901.jpg';
  image_8 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_08_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_08_2021071901.jpg';
  image_9 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_09_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_09_2021071901.jpg';
  image_10 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_10_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_10_2021071901.jpg';
  image_11 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_11_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_11_2021071901.jpg';
  image_12 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_12_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_12_2021071901.jpg';
  image_13 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_13_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_13_2021071901.jpg';
  image_14 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_14_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_14_2021071901.jpg';
  image_15 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_15_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_15_2021071901.jpg';
  image_16 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_16_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_16_2021071901.jpg';
  image_17 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_17_2021071901.jpg' : 'assets/images/about/Sustainable/Sustainable_17_2021071901.jpg';
  image_18 = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/Sustainable_18_2021071901.png' : 'assets/images/about/Sustainable/Sustainable_18_2021071901.png';

  image_a = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_a_en_20210617.png' : 'assets/images/about/Culture/Culture_a_th_20210617.png';
  image_m = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_m_en_20210617.png' : 'assets/images/about/Culture/Culture_m_th_20210617.png';
  image_i = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_i_en_20210617.png' : 'assets/images/about/Culture/Culture_i_th_20210617.png';
  image_s = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_s_en_20210617.png' : 'assets/images/about/Culture/Culture_s_th_20210617.png';
  imageThumnal = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_en_20210617.jpg' : 'assets/images/about/Culture/Culture_th_20210617.jpg';
  
  constructor(public langService: LangService, private _lightbox: Lightbox) { }
  private _albums: any[] = [];

  ngOnInit() {
    this._albums.push(
      {
        src: 'assets/images/about/Sustainable/pop_03_20210719.png',
        caption: '',
        thumb: ''
      },
      {
        src: 'assets/images/about/Sustainable/pop_04_20210719.png',
        caption: '',
        thumb: ''
      },
      {
        src: 'assets/images/about/Sustainable/pop_08_20210719.png',
        caption: '',
        thumb: ''
      },
      {
        src: 'assets/images/about/Sustainable/pop_09_20210719.png',
        caption: '',
        thumb: ''
      },
      {
        src: 'assets/images/about/Sustainable/pop_11_20210719.png',
        caption: '',
        thumb: ''
      },
      {
        src: 'assets/images/about/Sustainable/pop_15_20210719.png',
        caption: '',
        thumb: ''
      }
    );
  }

  open(index: number) {
    this._lightbox.open(this._albums, index);
  }

  close(): void {
    this._lightbox.close();
  }

}
