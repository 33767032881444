import { Component, OnInit, ViewChild } from '@angular/core';
import { AgmMap } from '@agm/core';

@Component({
  selector: 'app-project-map',
  templateUrl: './project-map.component.html',
  styleUrls: ['./project-map.component.css']
})
export class ProjectMapComponent implements OnInit {
  @ViewChild('agmMap', { static: false }) agmMap: AgmMap;
  zoom = 11.5;

  dataLocation: any[] = [{
    lat: 13.7548618,
    lng: 100.5817232,
    name: 'Rama 9'
  },
  {
    lat: 13.7309523,
    lng: 100.5606147,
    name: 'The Collection'
  },
  {
    lat: 13.8009644,
    lng: 100.5726817,
    name: 'Siamese Exclusive Ratchada'
  },
  {
    lat: 13.7232858,
    lng: 100.5584437,
    name: 'Siamese Exclusive Queens'
  },
  {
    lat: 13.711241,
    lng: 100.520016,
    name: 'Blossom Condo @ Sathorn-Charoenrat'
  },
  {
    lat: 13.70137,
    lng: 100.603323,
    name: 'Siamese Sukhumvit 87'
  },
  {
    lat: 13.7382364,
    lng: 100.5639533,
    name: 'Siamese Exclusive 31'
  },
  {
    lat: 13.709244,
    lng: 100.598815,
    name: 'Siamese Sukhumvit 48'
  },
  {
    lat: 13.729786,
    lng: 100.5273368,
    name: 'Siamese Surawong'
  },
  {
    lat: 13.7391566,
    lng: 100.5726406,
    name: 'Siamese 39'
  },
  {
    lat: 13.742971,
    lng: 100.5688083,
    name: 'Siamese Gioia'
  },
  {
    lat: 13.7212175,
    lng: 100.5779289,
    name: 'Siamese Exclusive 42'
  },
  {
    lat: 13.7004062,
    lng: 100.5440531,
    name: 'Siamese Nanglinchee Condo'
  }];
  constructor() { }

  ngOnInit() {
  }

}
