import { Component, OnInit } from '@angular/core';

import sampleData from '../../../assets/data/projects_tmp.json';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild
} from '@angular/animations';
import { OrderPipe } from 'ngx-order-pipe';
@Component({
  selector: 'app-project-ready',
  templateUrl: './project-ready.component.html',
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }), // initial
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({ transform: 'scale(1)', opacity: 1 })
        ) // final
      ]),
      transition(':leave', [
        style({ transform: 'scale(1)', opacity: 1, height: '*' }),
        animate(
          '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
          style({
            transform: 'scale(0.5)',
            opacity: 0,
            height: '0px',
            margin: '0px'
          })
        )
      ])
    ]),
    trigger('list', [
      transition(':enter', [
        query('@items', stagger(300, animateChild()), { optional: true })
      ])
    ])
  ],
  styleUrls: ['./project-ready.component.css']
})
export class ProjectReadyComponent implements OnInit {
  modelBtnFilter: any = {
    filter: ''
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;
  constructor(public orderPipe: OrderPipe) {
    this.modelProjectsFiltter = orderPipe.transform(
      this.modelProjects,
      'order'
    );
    this.modelProjectsFiltter = Object.assign(
      [],
      this.modelProjectsFiltter
    ).filter(item => item.isReady === 'Y');
  }

  ngOnInit() {
    this.modelBtnFilter.filter = 'A';
  }

  onfilter(filter) {
    debugger;
    this.modelBtnFilter.filter = filter;
    this.modelProjectsFiltter = [];
    if (filter === 'A') {
      this.modelProjectsFiltter = Object.assign(
        [],
        this.modelProjects
      ).filter(item => item.isReady === 'Y');
    } else if (filter !== 'A') {

      this.modelProjectsFiltter = Object.assign(
        [],
        this.modelProjects
      ).filter(item => item.isReady === 'Y');

      // tslint:disable-next-line: only-arrow-functions
      this.modelProjectsFiltter = this.modelProjectsFiltter.filter(function (el: any) {
        return el.type.toUpperCase().indexOf(filter) > -1;
      });
    }
    this.modelProjectsFiltter = this.orderPipe.transform(
      this.modelProjectsFiltter,
      'order'
    );
  }
}
