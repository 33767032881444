import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-cal-paid',
  templateUrl: './buyer-cal-paid.component.html',
  styleUrls: ['./buyer-cal-paid.component.css']
})
export class BuyerCalPaidComponent implements OnInit {
  salary: any;
  debt: any;
  year: any;
  interest: any;
  model: any = {
    payMounth: 0,
    all: 0
  };
  constructor() { }

  ngOnInit() {
  }

  calPaid(salary, debt, year, interest) {

    const duration = year * 12;
    interest = interest / 12 / 100;
    const salaryYear = salary * 12;
    let pay = (salary - debt) * (35 / 100);
    pay = Math.floor(Math.ceil(pay) * 100) / 100;
    let value = this.PV(interest, duration, pay);
    value = Math.floor(Math.ceil(value) * 10000) / 10000;
    this.model.payMounth = pay;
    this.model.all = value;
    // const valueC = valueB * 1000000 / 7000;

  }

  PV(interest, duration, pay) {
    if (interest <= 0) { return 0; }
    try {
      const Z = 1 / (1 + (interest / 1));
      return (pay * Z * (1 - Math.pow(Z, duration))) / (1 - Z);
    } catch   {
      return 0;
    }
  }

}
