import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-main',
  templateUrl: './about-main.component.html',
  styleUrls: ['./about-main.component.css']
})
export class AboutMainComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false
  };
  constructor() { }

  ngOnInit() {
    this.modalTab.tab1 = true;
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;
    this.modalTab.tab4 = false;
    this.modalTab.tab5 = false;
    this.modalTab.tab6 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab === 3) {
      this.modalTab.tab3 = true;
    } else if (selectTab === 4) {
      this.modalTab.tab4 = true;
    } else if (selectTab === 5) {
      this.modalTab.tab5 = true;
    } else if (selectTab === 6) {
      this.modalTab.tab6 = true;
    }
  }

}
