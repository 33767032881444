import { Component, OnInit } from '@angular/core';
import { UIParams, UIResponse, FacebookService, InitParams } from 'ngx-facebook';
import { Router, ActivatedRoute } from '@angular/router';
import sampleData from '../../../assets/data/newsDetail.json';
@Component({
  selector: 'app-footer-shares',
  templateUrl: './footer-shares.component.html',
  styleUrls: ['./footer-shares.component.css']
})
export class FooterSharesComponent implements OnInit {

  constructor(private fb: FacebookService, private router: Router, private route: ActivatedRoute) {
    const initParams: InitParams = {
      appId: '397644680605884',
      version: 'v3.0'
    };

    fb.init(initParams);
  }
  msgTwitter = '';
  newsData: any = {};
  ngOnInit() {

    this.route
      .queryParams
      .subscribe(params => {
        const id = params.id || 0;
        this.newsData = sampleData.find(x => x.ID === id);

      });
    const urlss = window.location.origin;
    const url = this.router.url;
    this.msgTwitter = 'https://twitter.com/intent/tweet?text=' + this.newsData.HeaderTh + ' ' + urlss + url;
  }
  shareWithOpenGraphActions() {
    const urlss = window.location.origin;
    const url = this.router.url;
    console.log(urlss + url);
    const paramsFb: UIParams = {
      method: 'share',
      href: urlss + url
    };
    this.fb.ui(paramsFb).then((res: UIResponse) => console.log(res)).catch((e: any) => console.error(e));
  }
}
