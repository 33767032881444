import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangService } from '../_service/lang.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {
  page: string;
  isCollapsed = true;
  selectLang: string;
  isCollapsedIR = true;

  items: string[] = [
    'TH',
    'EN'
  ];
  changeText: boolean;
  constructor(private router: Router, private langService: LangService) { this.changeText = false; }

  ngOnInit() {
    this.selectLang = this.langService.currLang();
  }

  openNavR() {
    document.getElementById('mySidenavR').style.width = '330px';
  }

  closeNavR() {
    document.getElementById('mySidenavR').style.width = '0';
  }

  goto(page) {
    if (page !== '') {
      this.router.navigate(['/' + page]);
      this.page = page;
      this.closeNavR();
    } else {
      this.router.navigate(['/']);
      this.page = 'index';
    }
  }

  setLang(lang) {
    this.langService.changeLang(lang);
    this.selectLang = lang;
    //window.location.reload();
  }
}
