import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false
  };
  // tslint:disable-next-line: variable-name
  constructor(private _lightbox: Lightbox) { }
  // tslint:disable-next-line: variable-name
  private _albums: any[] = [];

  ngOnInit() {
    const album = {
      src: 'assets/images/contact/map_en.jpg',
      caption: 'แผนที่',
      thumb: ''
    };

    this._albums.push(album);
    this.modalTab.tab1 = true;
  }

  viewMap() {
    this._lightbox.open(this._albums, 0);
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    }
  }
}
