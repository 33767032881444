import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild
} from "@angular/animations";
import { OwlOptions } from 'ngx-owl-carousel-o';
import sampleData from '../../../assets/data/award.json';
import { Router } from '@angular/router';
import { LangService } from 'src/app/_service/lang.service.js';

@Component({
  selector: 'app-about-award',
  templateUrl: './about-award.component.html',
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ) // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px"
          })
        )
      ])
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true })
      ])
    ])
  ],
  styleUrls: ['./about-award.component.css']
})
export class AboutAwardComponent implements OnInit {
  model: any = {
    month: "",
    year: ""
  };

  modelBtnFilter: any = {
    filter: ""
  };

  modelProjectsFiltter: any[] = [];
  modelProjects: any[] = sampleData;
  page = 1;

  constructor(private router: Router, public langService: LangService) { }

  ngOnInit() {
    // this.modelProjectsFiltter = this.paginate(this.modelProjects, 4, this.page);
    const curDate = new Date();
    //this.model.month = ("0" + (curDate.getMonth() + 1)).slice(-2);
    //this.model.year = curDate.getFullYear();
    this.model.month = "";
    this.model.year = "";
    this.filter(this.model.month, this.model.year);
  }

  filter(month, year) {
    this.page = 1;
    this.modelProjectsFiltter = [];
    // tslint:disable-next-line: only-arrow-functions
    this.modelProjectsFiltter = this.modelProjects.filter(function(el: any) {
      return (
        (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
        (el.year.toUpperCase().indexOf(year) === 0 || year === "")
      );
    }).sort((a, b) => (a.newsDate < b.newsDate) ? 1 : ((b.newsDate < a.newsDate) ? -1 : 0));

    this.modelProjectsFiltter = this.paginate(
      this.modelProjectsFiltter,
      4,
      this.page
    );
  }

  paginate(array, pageSize, pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  viewMore(month, year) {
    this.page = this.page + 1;

    const dataview = this.modelProjects.filter(function(el: any) {
      return (
        (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
        (el.year.toUpperCase().indexOf(year) === 0 || year === "")
      );
    }).sort((a, b) => (a.newsDate < b.newsDate) ? 1 : ((b.newsDate < a.newsDate) ? -1 : 0));

    const resp = this.paginate(dataview, 4, this.page);
    resp.forEach(item => {
      this.modelProjectsFiltter.push(item);
    });
  }

  goto(page) {
    this.router.navigate(["/career-details"], { queryParams: { id: page } });
  }
}

export interface Food {
  value: string;
  viewValue: string;
}
