import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-agent-form',
  templateUrl: './agent-form.component.html',
  styleUrls: ['./agent-form.component.css']
})
export class AgentFormComponent implements OnInit {
  registerForm: FormGroup;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initRegisterForm();
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        firstName: [
          '',
          Validators.compose([
            Validators.required
          ])
        ],
        lastName: [
          '',
          Validators.compose([
            Validators.required
          ])
        ],
        position: ['', Validators.required],
        department: ['', Validators.required],
        companyAgency: ['', Validators.required],
        address1: ['', Validators.required],
        address2: [''],
        zipCode: ['', Validators.required],
        mobile: ['', Validators.required],
        email: ['', Validators.required]
      }
    );
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result =
      control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

}
