import { Component, OnInit } from '@angular/core';

import sampleData from '../../../assets/data/newsDetail.json';
import { ActivatedRoute } from '@angular/router';
import {
  FacebookService,
  InitParams,
  UIParams,
  UIResponse
} from 'ngx-facebook';
import { LangService } from 'src/app/_service/lang.service.js';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-page-details',
  templateUrl: './page-details.component.html',
  styleUrls: ['./page-details.component.css']
})
export class PageDetailsComponent implements OnInit {
  modelNewDetail: any = {
    header: '',
    img1: '',
    paragraph1: '',
    img2: '',
    paragraph2: '',
    img3: '',
    paragraph3: '',
    img4: '',
    paragraph4: '',
    credit: '',
    year: '',
    month: '',
    date: '',
    usevdo: '',
    vdo: ''
  };
  constructor(
    private route: ActivatedRoute,
    private fb: FacebookService,
    public langService: LangService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const id = params.id || 0;
      const obj = sampleData.find(x => x.ID === id);
      this.modelNewDetail.header =
        this.langService.currLang() === 'en' ? obj.HeaderEn : obj.HeaderTh;
      this.modelNewDetail.img1 =
        this.langService.currLang() === 'en' ? obj.Img1En : obj.Img1Th;
      this.modelNewDetail.paragraph1 =
        this.langService.currLang() === 'en'
          ? obj.paragraph1En
          : obj.paragraph1Th;
      this.modelNewDetail.img2 = this.langService.currLang() === 'en' ? obj.Img2En : obj.Img2Th;
      this.modelNewDetail.paragraph2 =
        this.langService.currLang() === 'en'
          ? obj.paragraph2En
          : obj.paragraph2Th;
      this.modelNewDetail.img3 = this.langService.currLang() === 'en' ? obj.Img3En : obj.Img3Th;
      this.modelNewDetail.paragraph3 =
        this.langService.currLang() === 'en'
          ? obj.paragraph3En
          : obj.paragraph3Th;
      this.modelNewDetail.img4 = this.langService.currLang() === 'en' ? obj.Img4En : obj.Img4Th;
      this.modelNewDetail.paragraph4 =
        this.langService.currLang() === 'en'
          ? obj.paragraph4En
          : obj.paragraph4Th;
      this.modelNewDetail.credit = obj.credit;
      this.modelNewDetail.year = obj.year;
      this.modelNewDetail.month = obj.month;
      this.modelNewDetail.date = obj.date;
      this.modelNewDetail.usevdo = obj.usevdo;
      this.modelNewDetail.vdo = this.sanitizer.bypassSecurityTrustResourceUrl(obj.vdo);
      // if (!(evt instanceof NavigationEnd)) {
      //   return;
      // }
      window.scrollTo(0, 0);
    });
  }
}
