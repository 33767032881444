import { Component, OnInit } from '@angular/core';
import { match } from 'minimatch';

@Component({
  selector: 'app-buyer-cal-lone',
  templateUrl: './buyer-cal-lone.component.html',
  styleUrls: ['./buyer-cal-lone.component.css']
})
export class BuyerCalLoneComponent implements OnInit {
  amount: any;
  interest: any;
  duration: any;
  model: any = {
    payMounth: 0,
    minimun: 0
  };
  constructor() { }

  ngOnInit() {
  }

  calLone(interest, duration, value) {

    duration = duration * 12;
    interest = interest / 12 / 100;
    value = value * -1;
    const payMentTaem = this.PMT(interest, duration, value);
    const pay = Math.floor(Math.ceil(payMentTaem) * 100) / 100;
    const minimun = Math.floor(Math.ceil(pay / (35 / 100)) * 1000) / 1000;
    this.model.minimun = minimun;
    this.model.payMounth = pay;
    // round(($pay / (35 / 100)), -3)
    // $pay = round(ceil($pmt), -2);
    // const valueC = valueB * 1000000 / 7000;

  }

  PMT(interest, duration, value) {
    if (interest <= 0) { return 0; }
    try {
      return interest * value * Math.pow((1 + interest), duration) / (1 - Math.pow((1 + interest), duration));
    } catch {
      return 0;
    }
  }

}
