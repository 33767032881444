import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-activities-main',
  templateUrl: './news-activities-main.component.html',
  styleUrls: ['./news-activities-main.component.css']
})
export class NewsActivitiesMainComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false
  };
  constructor() { }

  ngOnInit() {
    this.modalTab.tab1 = true;
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab === 3) {
      this.modalTab.tab3 = true;
    }
  }

}
