import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class LangService {
  constructor(
    private translate: TranslateService,
    private cookieService: CookieService
  ) {}

  changeLang(lang: string) {
    lang = lang ? lang : "th";
    this.translate.setDefaultLang(lang.toLocaleLowerCase());
    this.cookieService.set("lang", lang);
    localStorage.setItem("lang", lang);
    document.cookie = "lang=" + lang;
  }

  currLang() {
    const lang = localStorage.getItem("lang");
    return lang;
  }
}
