import { MainComponent } from "./main/main.component";
import { Routes } from "@angular/router";
import { ProjectMainComponent } from "./projects/project-main/project-main.component";
import { AboutMainComponent } from "./about/about-main/about-main.component";
import { NewsActivitiesMainComponent } from "./news-activities/news-activities-main/news-activities-main.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { AgentMainComponent } from "./agent/agent-main/agent-main.component";
import { CareerMainComponent } from "./career/career-main/career-main.component";
import { BuyerToolsMainComponent } from "./buyer-tools/buyer-tools-main/buyer-tools-main.component";
import { PageDetailsComponent } from "./news-activities/page-details/page-details.component";
import { IrPoliciesComponent } from "./IR/ir-policies/ir-policies.component";
import { CareerDetailsComponent } from "./career/career-details/career-details.component";
import { DebentureComponent } from "./debenture/debenture.component";

export const appRoutes: Routes = [
  { path: "", component: MainComponent },
  { path: "projects/:id", component: ProjectMainComponent },
  { path: "projects", component: ProjectMainComponent },
  { path: "about", component: AboutMainComponent },
  { path: "news", component: NewsActivitiesMainComponent },
  { path: "news-detail", component: PageDetailsComponent },
  { path: "contactUs", component: ContactUsComponent },
  { path: "agent", component: AgentMainComponent },
  { path: "career", component: CareerMainComponent },
  { path: "buyer", component: BuyerToolsMainComponent },
  { path: "ir-policies", component: IrPoliciesComponent },
  { path: "career-details", component: CareerDetailsComponent },
  { path: "debenture", component: DebentureComponent },
  { path: "**", redirectTo: "", pathMatch: "full" },
];
