

import { AgmCoreModule } from '@agm/core';
import { OrderModule } from 'ngx-order-pipe';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { SlideshowModule } from 'ng-simple-slideshow';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ContentslideComponent } from './View/contentslide/contentslide.component';
import { MainComponent } from './main/main.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { ProjectsComponent } from './View/projects/projects.component';
// tslint:disable-next-line: max-line-length
import { MatIconModule, MatStepperModule, MatButtonModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatTableModule, MatPaginatorModule, MatTabsModule, MatChipsModule } from '@angular/material';
import { ReadytomoveComponent } from './View/readytomove/readytomove.component';
import { WhatnewsComponent } from './View/whatnews/whatnews.component';
import { Contentslide2Component } from './View/contentslide2/contentslide2.component';
import { FooterComponent } from './View/footer/footer.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { ProjectMainComponent } from './projects/project-main/project-main.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProjectAllComponent } from './projects/project-all/project-all.component';
import { ProjectMapComponent } from './projects/project-map/project-map.component';
import { AboutMainComponent } from './about/about-main/about-main.component';
import { AboutVisionComponent } from './about/about-vision/about-vision.component';
import { AboutOrgComponent } from './about/about-org/about-org.component';
import { AboutHistoryComponent } from './about/about-history/about-history.component';
import { AboutCultureComponent } from './about/about-culture/about-culture.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NewsActivitiesMainComponent } from './news-activities/news-activities-main/news-activities-main.component';
import { NewsComponent } from './news-activities/news/news.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ActivitiesComponent } from './news-activities/activities/activities.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LightboxModule } from 'ngx-lightbox';
import { AgentMainComponent } from './agent/agent-main/agent-main.component';
import { AgentFormComponent } from './agent/agent-form/agent-form.component';
import { UploadComponent } from './upload/upload.component';
import { CareerMainComponent } from './career/career-main/career-main.component';
import { CareerGridComponent } from './career/career-grid/career-grid.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BuyerToolsMainComponent } from './buyer-tools/buyer-tools-main/buyer-tools-main.component';
import { BuyerCalPaidComponent } from './buyer-tools/buyer-cal-paid/buyer-cal-paid.component';
import { BuyerCalLoneComponent } from './buyer-tools/buyer-cal-lone/buyer-cal-lone.component';
import { ProjectReadyComponent } from './projects/project-ready/project-ready.component';
import { PageDetailsComponent } from './news-activities/page-details/page-details.component';
import { FooterSharesComponent } from './news-activities/footer-shares/footer-shares.component'; // Import your library
import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { CookieService } from 'ngx-cookie-service';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'; // Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { FacebookModule } from 'ngx-facebook';
import { IrPoliciesComponent } from './IR/ir-policies/ir-policies.component';
import { IntelligentComponent } from './news-activities/intelligent/intelligent.component';
import { CareerDetailsComponent } from './career/career-details/career-details.component';
import { ProjectInnovationComponent } from './projects/project-innovation/project-innovation.component';
import { AboutCompanyComponent } from './about/about-company/about-company.component';
import { AboutIntelligentComponent } from './about/about-intelligent/about-intelligent.component';
import { AboutSustainableComponent } from './about/about-sustainable/about-sustainable.component';
import { AboutGoalsComponent } from './about/about-goals/about-goals.component';
import { AboutEsgComponent } from './about/about-esg/about-esg.component';
import { AboutEnvironmentComponent } from './about/about-environment/about-environment.component';
import { AboutSocialComponent } from './about/about-social/about-social.component';
import { AboutGovernanceComponent } from './about/about-governance/about-governance.component';
import { AboutAwardComponent } from './about/about-award/about-award.component';
import { CareerTestimonialComponent } from './career/career-testimonial/career-testimonial.component';
import { FreamComponent } from './fream/fream.component';
import { HotelsComponent } from './projects/hotels/hotels.component';
import { FAndBComponent } from './projects/f-and-b/f-and-b.component';
import { HealComponent } from './projects/heal/heal.component';
import { TechComponent } from './projects/tech/tech.component';
import { InvestComponent } from './projects/invest/invest.component';
import { DebentureComponent } from './debenture/debenture.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidenavComponent,
    ContentslideComponent,
    MainComponent,
    ProjectsComponent,
    ReadytomoveComponent,
    WhatnewsComponent,
    Contentslide2Component,
    FooterComponent,
    ProjectMainComponent,
    ProjectAllComponent,
    ProjectMapComponent,
    AboutMainComponent,
    AboutVisionComponent,
    AboutOrgComponent,
    AboutHistoryComponent,
    AboutCultureComponent,
    NewsActivitiesMainComponent,
    NewsComponent,
    ActivitiesComponent,
    ContactUsComponent,
    AgentMainComponent,
    AgentFormComponent,
    UploadComponent,
    CareerMainComponent,
    CareerGridComponent,
    BuyerToolsMainComponent,
    BuyerCalPaidComponent,
    BuyerCalLoneComponent,
    ProjectReadyComponent,
    PageDetailsComponent,
    FooterSharesComponent,
    IrPoliciesComponent,
    IntelligentComponent,
    CareerDetailsComponent,
    ProjectInnovationComponent,
    AboutCompanyComponent,
    AboutIntelligentComponent,
    AboutSustainableComponent,
    AboutGoalsComponent,
    AboutEsgComponent,
    AboutEnvironmentComponent,
    AboutSocialComponent,
    AboutGovernanceComponent,
    AboutAwardComponent,
    CareerTestimonialComponent,
    FreamComponent,
    HotelsComponent,
    FAndBComponent,
    HealComponent,
    TechComponent,
    InvestComponent,
    DebentureComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SlideshowModule,
    RouterModule.forRoot(appRoutes),
    MatCardModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    TabsModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDCJnbGjbN28Vy0owwjWHS1FnJ8oFSUYy0'
    }),
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    LightboxModule,
    MatTableModule,
    MatPaginatorModule,
    CollapseModule.forRoot(),
    OrderModule,
    MatTabsModule,
    MatChipsModule,
    NgxMaskModule.forRoot(),
    OwlModule,
    CarouselModule.forRoot(),
    BsDropdownModule.forRoot(),
    FacebookModule.forRoot(),
    NgxImageZoomModule.forRoot()
  ],
  providers: [CookieService],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }


