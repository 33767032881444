import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-sustainable',
  templateUrl: './about-sustainable.component.html',
  styleUrls: ['./about-sustainable.component.css']
})
export class AboutSustainableComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false
  };

  menuValue: any;

  constructor() { }

  ngOnInit() {
    this.selectTab(1);
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;
    this.modalTab.tab4 = false;
    this.modalTab.tab5 = false;
    this.menuValue = selectTab;

    if (selectTab == 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab == 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab == 3) {
      this.modalTab.tab3 = true;
    } else if (selectTab == 4) {
      this.modalTab.tab4 = true;
    } else if (selectTab == 5) {
      this.modalTab.tab5 = true;
    }
  }

}
