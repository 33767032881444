import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { RespDto } from "../_model/respDto";
import bannerData from "../../assets/data/banner.json";
import timelineData from "../../assets/data/timeline.json";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  // ให้เอา coment ออกเมืือทำ service เสร็จ
  // ตอนนี้ให้อ่านข้้อมูลจากไฟล์ Json
  // baseUrl = environment.apiUrl + environment.adminApi;
  constructor(private http: HttpClient) {}

  getBanner(lang) {
    const respData = Array();
    bannerData.forEach(item => {
      respData.push({
        url: lang === "en" ? item.imageEng : item.imageTh,
        title: item.title,
        urlWeb: item.url
      });
    });

    return respData;
    // const httpHeaders = new HttpHeaders();
    // httpHeaders.set('Content-Type', 'application/json');
    // return this.http
    //   .post(
    //     // this.baseUrl + '/GetUserWaitApprove',
    //     {},
    //     {
    //       headers: httpHeaders
    //     }
    //   )
    //   .pipe(map((resp: RespDto) => resp as RespDto));
  }

  getTimeline(lang) {
     
    const respData = Array();
    timelineData.forEach(item => {
      respData.push({
        url: lang === "en" ? item.imageEng : item.imageTh,
        title: item.title
      });
    });

    return respData;
  }
}
