import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';
import { ApiService } from 'src/app/_service/api.service';

@Component({
  selector: 'app-contentslide',
  templateUrl: './contentslide.component.html',
  styleUrls: ['./contentslide.component.css']
})
export class ContentslideComponent implements OnInit {
  imageUrls: any[] = [];


  constructor(public langService: LangService, private apiService: ApiService) { }

  ngOnInit() {
    this.imageUrls = this.apiService.getBanner(this.langService.currLang());
  }

}
