import { Component, OnInit } from '@angular/core';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild
} from "@angular/animations";

import sampleData from "../../../assets/data/intelligent.json";//Mark
import { Router } from "@angular/router";
import { LangService } from "src/app/_service/lang.service.js";

@Component({
  selector: 'app-intelligent',
  templateUrl: './intelligent.component.html',
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ) // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px"
          })
        )
      ])
    ]),
    trigger("list", [
      transition(":enter", [
        query("@items", stagger(300, animateChild()), { optional: true })
      ])
    ])
  ],
  styleUrls: ['./intelligent.component.css']
})
export class IntelligentComponent implements OnInit {
  [x: string]: any;
  page = 1;
  model: any = {
    month: "",
    year: ""
  };

  modelBtnFilter: any = {
    filter: ""
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;

  modelHilight: any = {
    title:
      this.langService.currLang() === "en"
        ? "SI ปีที่ 2 ฉบับที่ 2 มี 6 บทความ เน้นเรื่องเกี่ยวกับสุขภาพ"
        : "SI ปีที่ 2 ฉบับที่ 2 มี 6 บทความ เน้นเรื่องเกี่ยวกับสุขภาพ",
    desc:
      this.langService.currLang() === "en"
        ? "บทความแรกเป็นเรื่องเกี่ยวกับกาฬโรคซึ่งเป็นโรคระบาดที่คร่าชีวิตผู้คนไปเป็นจำนวนมาก บทความที่สองเป็นเรื่องเกี่ยวกับสภาพความเป็นอยู่ของคนยากจนในเอดินบะระที่ต้องอาศัยอยู่อย่างแออัดในเมืองใต้ดิน บทความที่สามนำเสนอเกี่ยวกับ WELL Building Standard และบทความที่สี่เกี่ยวกับ fitwel ซึ่งเป็นมาตรฐานทางสุขภาวะของอาคารที่ออกมาหลังมาตรฐาน WELL บทควาทที่ห้านำเสนอเรื่องราวของโรงเรียนสตรีในชนบทของรัฐราชสถาน ประเทศอินเดียที่ได้รับรางวัลการออกแบบระดับโลกหลายรางวัล บทความสุดท้ายเป็นรายละเอียดของโครงการหลักของเขตพัฒนาพิเศษภาคตะวันออกซึ่งเป็นตอนที่ 2"
        : "บทความแรกเป็นเรื่องเกี่ยวกับกาฬโรคซึ่งเป็นโรคระบาดที่คร่าชีวิตผู้คนไปเป็นจำนวนมาก บทความที่สองเป็นเรื่องเกี่ยวกับสภาพความเป็นอยู่ของคนยากจนในเอดินบะระที่ต้องอาศัยอยู่อย่างแออัดในเมืองใต้ดิน บทความที่สามนำเสนอเกี่ยวกับ WELL Building Standard และบทความที่สี่เกี่ยวกับ fitwel ซึ่งเป็นมาตรฐานทางสุขภาวะของอาคารที่ออกมาหลังมาตรฐาน WELL บทควาทที่ห้านำเสนอเรื่องราวของโรงเรียนสตรีในชนบทของรัฐราชสถาน ประเทศอินเดียที่ได้รับรางวัลการออกแบบระดับโลกหลายรางวัล บทความสุดท้ายเป็นรายละเอียดของโครงการหลักของเขตพัฒนาพิเศษภาคตะวันออกซึ่งเป็นตอนที่ 2",
    img:
      this.langService.currLang() === "en"
        ? "assets/images/news/INTEL_008.jpg"
        : "assets/images/news/INTEL_008.jpg"
  };

  constructor(private router: Router, public langService: LangService) {}

  ngOnInit() {
    // this.modelProjectsFiltter = this.paginate(this.modelProjects, 4, this.page);
    const curDate = new Date();
    //this.model.month = ("0" + (curDate.getMonth() + 1)).slice(-2);
    //this.model.year = curDate.getFullYear();
    this.model.month = "";
    this.model.year = "";
    this.filter(this.model.month, this.model.year);
  }

  filter(month, year) {
    this.page = 1;
    this.modelProjectsFiltter = [];
    // tslint:disable-next-line: only-arrow-functions
    this.modelProjectsFiltter = this.modelProjects
      .filter(function(el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    this.modelProjectsFiltter = this.paginate(
      this.modelProjectsFiltter,
      4,
      this.page
    );
  }

  paginate(array, pageSize, pageNumber) {
    --pageNumber; // because pages logically start with 1, but technically with 0
    return array.slice(pageNumber * pageSize, (pageNumber + 1) * pageSize);
  }

  viewMore(month, year) {
    this.page = this.page + 1;

    const dataview = this.modelProjects
      .filter(function(el: any) {
        return (
          (el.month.toUpperCase().indexOf(month) === 0 || month === "") &&
          (el.year.toUpperCase().indexOf(year) === 0 || year === "")
        );
      })
      .sort((a, b) =>
        a.newsDate < b.newsDate ? 1 : b.newsDate < a.newsDate ? -1 : 0
      );

    const resp = this.paginate(dataview, 4, this.page);

    resp.forEach(item => {
      this.modelProjectsFiltter.push(item);
    });
  }

  goto(page) {
    this.router.navigate(["/news-detail"], { queryParams: { id: page } });
  }
}

export interface Food {
  value: string;
  viewValue: string;
}
