import { Component, OnInit } from '@angular/core';
import { LangService } from '../_service/lang.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  showModal = true
  constructor( private langService: LangService) { 
    if (this.langService.currLang() === '') {
      langService.changeLang('th');
    } else {
      langService.changeLang(this.langService.currLang());
    }
  }

  ngOnInit() {
     
    this.showModal = true
  }

}
