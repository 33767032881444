import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-org',
  templateUrl: './about-org.component.html',
  styleUrls: ['./about-org.component.css']
})
export class AboutOrgComponent implements OnInit {
  // tslint:disable-next-line: variable-name
  private _albums: any[] = [];
  constructor(public langService: LangService) { }

  // tslint:disable-next-line: max-line-length
  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Organization_Structure/ORG_ENG_2021050701.jpg' : 'assets/images/about/Organization_Structure/ORG_THA_2021050701.jpg';
  // tslint:disable-next-line: max-line-length
  doc = (this.langService.currLang() === 'en') ? 'assets/doc/Org/Organization-Structure-Eng.pdf' : 'assets/doc/Org/Organization-Structure-TH.pdf';



  ngOnInit() {
  }

  view() {

  }

}
