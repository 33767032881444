import { Component, OnInit, ViewChild } from "@angular/core";
import { IImage } from "ng-simple-slideshow";
import { AgmMap } from "@agm/core";
import { CarouselConfig } from "ngx-bootstrap/carousel";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-project-main",
  templateUrl: "./project-main.component.html",
  providers: [
    {
      provide: CarouselConfig,
      useValue: { interval: 3000, noPause: false, showIndicators: true },
    },
  ],
  styleUrls: ["./project-main.component.css"],
})
export class ProjectMainComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false,
    tab5: false,
    tab6: false,
  };

  public agmMap: AgmMap;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.modalTab.tab1 = true;
    this.route.paramMap.subscribe((params) => {
      const id = params.get("id");
      if (id === "4") {
        this.selectTab(4);
      } else {
        this.selectTab(1);
      }
    });
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;
    this.modalTab.tab4 = false;
    this.modalTab.tab5 = false;
    this.modalTab.tab6 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab === 3) {
      this.modalTab.tab3 = true;
    } else if (selectTab === 4) {
      this.modalTab.tab4 = true;
    } else if (selectTab === 5) {
      this.modalTab.tab5 = true;
    } else if (selectTab === 6) {
      this.modalTab.tab6 = true;
    }
  }
}
