import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-debenture',
  templateUrl: './debenture.component.html',
  styleUrls: ['./debenture.component.sass']
})
export class DebentureComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
