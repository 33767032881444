import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-environment',
  templateUrl: './about-environment.component.html',
  styleUrls: ['./about-environment.component.css']
})
export class AboutEnvironmentComponent implements OnInit {

  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/environment_00_2021072010.jpg' : 'assets/images/about/Sustainable/environment_00_2021072010.jpg';

  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
