import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-tools-main',
  templateUrl: './buyer-tools-main.component.html',
  styleUrls: ['./buyer-tools-main.component.css']
})
export class BuyerToolsMainComponent implements OnInit {
  modalTab: any = {
    tab1: false
  };

  constructor() { }

  ngOnInit() {
    this.modalTab.tab1 = true;
  }

}
