import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-culture',
  templateUrl: './about-culture.component.html',
  styleUrls: ['./about-culture.component.css']
})
export class AboutCultureComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_en_20210617.png' : 'assets/images/about/Culture/Culture_th_20210617.png';
  image_a = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_a_en_20210617.png' : 'assets/images/about/Culture/Culture_a_th_20210617.png';
  image_m = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_m_en_20210617.png' : 'assets/images/about/Culture/Culture_m_th_20210617.png';
  image_i = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_i_en_20210617.png' : 'assets/images/about/Culture/Culture_i_th_20210617.png';
  image_s = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_s_en_20210617.png' : 'assets/images/about/Culture/Culture_s_th_20210617.png';
  imageThumnal = (this.langService.currLang() === 'en') ? 'assets/images/about/Culture/Culture_en_20210617.jpg' : 'assets/images/about/Culture/Culture_th_20210617.jpg';
  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
