import { ApiService } from './../../_service/api.service';
import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';
import { LangService } from 'src/app/_service/lang.service';
@Component({
  selector: 'app-contentslide2',
  templateUrl: './contentslide2.component.html',
  styleUrls: ['./contentslide2.component.css']
})
export class Contentslide2Component implements OnInit {
  imageUrls: any[] = [];


  constructor(public langService: LangService, private apiService: ApiService) { }

  ngOnInit() {
    this.imageUrls = this.apiService.getTimeline(this.langService.currLang());
  }

}
