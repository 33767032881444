import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-career-testimonial',
  templateUrl: './career-testimonial.component.html',
  styleUrls: ['./career-testimonial.component.css']
})
export class CareerTestimonialComponent implements OnInit {

  image = (this.langService.currLang() === 'en') ? 'assets/images/career/testimonial/testimonial_20211201.jpeg' : 'assets/images/career/testimonial/testimonial_20211201.jpeg';
  usevdo = 'Y';
  vdo = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/-IyFz7BtgPs');
  vdo1 = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/fZlddoi2W-s');

  constructor(public langService: LangService, private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

}
