import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  modelData: any[] = [
    {
      siteUrl: 'https://siameseholm.com/',
      imageMain: 'assets/images/home/Section2/holm_banner.jpg',
      imageHover: 'assets/images/logos_ci/holm_logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Siamese Holm Phahol-Vibhavadi' : 'ไซมิสโฮล์ม พหลฯ-วิภาวดี',
      title1: (this.langService.currLang() === 'en') ? '' : '',
      line1: (this.langService.currLang() === 'en') ? 'Phahol-Vibhavadi' : 'พหลโยธิน-วิภาวดีรังสิต',
      line2: (this.langService.currLang() === 'en') ? '5 Km.from BTS Rangsit Station' : '5 กม. รถไฟฟ้า สถานีรังสิต',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? '2 Km.from Future Park Rangsit' : '2 กม. ฟิวเจอร์พาร์ครังสิต',

      isOver: false

    },
    {
      siteUrl: 'https://siamese-blossom.com/',
      imageMain: 'assets/images/home/Section2/blossom_banner.jpg',
      imageHover: 'assets/images/logos_ci/blossom_logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Siamese Blossom Phahol-Vibhavadi' : 'ไซมิสบลอสซั่ม พหลฯ-วิภาวดี',
      title1: (this.langService.currLang() === 'en') ? '' : '',
      line1: (this.langService.currLang() === 'en') ? 'Phahol-Vibhavadi' : 'พหลโยธิน-วิภาวดีรังสิต',
      line2: (this.langService.currLang() === 'en') ? '5 Km.from BTS Rangsit Station' : '5 กม. รถไฟฟ้า สถานีรังสิต',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? '2 Km.from Future Park Rangsit' : '2 กม. ฟิวเจอร์พาร์ครังสิต',

      isOver: false

    },
    {
      siteUrl: 'https://landmarkkaset.com/',
      imageMain: 'assets/images/home/Section2/coverkaset.jpg',
      imageHover: 'assets/images/logos_ci/20231102-01logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Landmark At Kaset TSH Station' : 'แลนด์มาร์ค แอท เกษตร',
      title1: (this.langService.currLang() === 'en') ? '' : '',
      line1: (this.langService.currLang() === 'en') ? 'Vibhavadi Rangsit' : 'วิภาวดีรังสิต',
      line2: (this.langService.currLang() === 'en') ? '0 meters from thung song hong' : '0 ม.* รถไฟฟ้าสายสีแดง ทุ่งสองห้อง',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',

      isOver: false 
    },
    {
      siteUrl: 'https://siamesekin2.com/',
      imageMain: 'assets/images/home/Section2/kin2_banner.jpg',
      imageHover: 'assets/images/logos_ci/kin_logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Siamese Kin Ramintra (Phase 2)' : 'ไซมิสคิน รามอินทรา (เฟส 2)',
      title1: (this.langService.currLang() === 'en') ? '' : '',
      line1: (this.langService.currLang() === 'en') ? 'Ratchada-Ramintra' : 'รัชดา-รามอินทรา',
      line2: (this.langService.currLang() === 'en') ? '1 Km.from BTS Wongwan-Ramintra Station' : '5 กม. รถไฟฟ้า สถานีวงแหวนรามอินทรา',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? '1 Km.from Fasion Island' : '1 กม. แฟชั่นไอส์แลนด์', 
      isOver: false

    },
    {
      siteUrl: 'https://monsaneexclusivevilla.com/',
      imageMain: 'assets/images/home/Section2/monsane33.jpg',
      imageHover: 'assets/images/logos_ci/monsane033.jpg',
      title: (this.langService.currLang() === 'en') ? 'Monsane Exclusive Villa' : 'มนต์เสน่ห์ เอ๊กซ์คลูซีพ วิลล่า',
      title1: (this.langService.currLang() === 'en') ? 'Ratchapruk-Pinklao' : 'ราชพฤกษ์-ปิ่นเกล้า',
      line1: (this.langService.currLang() === 'en') ? 'Phrannok-Phutthamonthon Sai4 Rd.' : 'พรานนก-พุทธมณฑลสาย 4',
      line2: (this.langService.currLang() === 'en') ? '7.6 Km.from MRT Fai Chai Station' : '7.6 กม.รถไฟฟ้าสายสีนำ้เงิน(สถานีแยกไฟฉาย)',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ', 
      isOver: false 
    },
    {
      siteUrl: 'https://monsane-chaengwatthana.com/',
      imageMain: 'assets/images/home/Section2/L1.jpg',
      imageHover: 'assets/images/logos_ci/L1.jpg',
      title: (this.langService.currLang() === 'en') ? 'MONSANE Ratchapruek-Chaengwatthana' : 'มนต์เสน่ห์ ราชพฤกษ์-แจ้งวัฒนะ',
      title1: (this.langService.currLang() === 'en') ? '' : '',
      line1: (this.langService.currLang() === 'en') ? 'Ratchapruek-Chaengwatthana' : 'ราชพฤกษ์-แจ้งวัฒนะ',
      line2: (this.langService.currLang() === 'en') ? 'Robinson life style Ratchapruek' : 'โรบินสัน ไลฟ์สไตล์ ราชพฤกษ์',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? 'Central Westgate' : 'เซ็นทรัล เวสต์เกต', 
      isOver: false 
    },
    
    {
      siteUrl: 'https://www.landmarkatgrandstation.com/',
      imageMain: 'assets/images/home/Section2/Preview-09.jpg',
      imageHover: 'assets/images/logos_ci/20231102-03logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Landmark At Grand Station' : 'แลนด์มาร์ค แอท แกรนด์ สเตชั่น',

      line1: (this.langService.currLang() === 'en') ? 'Ratchada-Ramintra' : 'รัชดา-รามอินทรา',
      line2: (this.langService.currLang() === 'en') ? '400 meters from MRTA Fashion Island' : '400 เมตร จากรถไฟฟ้า แฟชั่น ไอส์แลนด์',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',
      isOver: false
    },
    {
      siteUrl: 'https://www.landmarkatmrtastation.com/',
      imageMain: 'assets/images/home/Section2/Preview-10.jpg',
      imageHover: 'assets/images/logos_ci/20231102-02logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'Landmark At MRTA Station' : 'แลนด์มาร์ค แอท เอ็มอาร์ทีเอ สเตชั่น',

      line1: (this.langService.currLang() === 'en') ? 'Rama 9' : 'พระราม 9',
      line2: (this.langService.currLang() === 'en') ? '1 minute from MRTA Station' : '1 นาที จากสถานี MRTA',
      // line3: 'Starts 2.99 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',
      isOver: false
    },
    // {
    //   siteUrl: 'https://siamesesukhumvit87.com/',
    //   imageMain: 'assets/images/home/Section2/Preview-08.jpg',
    //   imageHover: 'assets/images/logos_ci/20231102-05logo.jpg',
    //   title: (this.langService.currLang() === 'en') ? 'SIAMESE SUKHUMVIT 87' : 'ไซมิส สุขุมวิท 87',
    //   line1: (this.langService.currLang() === 'en') ? 'Sukhumvit 87' : 'สุขุมวิท 87',
    //   line2: (this.langService.currLang() === 'en') ? '350 meters from BTS Onnut' : '350 เมตร จาก BTS อ่อนนุช',
    //   // line3: 'Starts 4.27 MB',
    //   line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',

    // },
    /*{
      siteUrl: 'https://www.collection16.com/',
      imageMain: 'assets/images/home/Section2/Preview-03.jpg',
      imageHover: 'assets/images/logos_ci/Siamese asset-logo-project-19.jpg',
      title: (this.langService.currLang() === 'en') ? 'The Collection' : 'เดอะ คอลเลคชั่น',
      line1: (this.langService.currLang() === 'en') ? 'Sukhumvit 16' : 'สุขุมวิท 16',
      line2: (this.langService.currLang() === 'en') ? '450 meters from BTS Asoke' : '450 เมตร จาก BTS อโศก',
      line3: 'Starts 6.39 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',

    },*/
    {
      siteUrl: 'https://siamese-sukhumvit48.com/',
      imageMain: 'assets/images/home/Section2/Preview-07.jpg',
      imageHover: 'assets/images/logos_ci/20231102-04logo.jpg',
      title: (this.langService.currLang() === 'en') ? 'SIAMESE SUKHUMVIT 48' : 'ไซมิส สุขุมวิท 48',
      line1: (this.langService.currLang() === 'en') ? 'Sukhumvit 48' : 'สุขุมวิท 48',
      line2: (this.langService.currLang() === 'en') ? '350 meters from BTS Onnut' : '350 เมตร จาก BTS อ่อนนุช',
      // line3: 'Starts 6.39 MB',
      line4: (this.langService.currLang() === 'en') ? 'Register for special privileges' : 'ลงทะเบียนเพื่อรับสิทธิพิเศษ',

    }
    

  ];


  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
