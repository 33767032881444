import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-agent-main',
  templateUrl: './agent-main.component.html',
  styleUrls: ['./agent-main.component.css']
})
export class AgentMainComponent implements OnInit {

  constructor(public langService: LangService) { }
  image = (this.langService.currLang() === 'en') ? 'assets/images/agent/bk-agent_en.jpg' : 'assets/images/agent/bk-agent_th.jpg';
  ngOnInit() {
  }

}
