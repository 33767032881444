import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.css']
})
export class AboutCompanyComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false,
    tab4: false
  };

  menuValue: any;

  constructor() { }

  ngOnInit() {
    this.selectTab(1);
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;
    this.modalTab.tab4 = false;
    this.menuValue = selectTab;

    if (selectTab == 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab == 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab == 3) {
      this.modalTab.tab3 = true;
    } else if (selectTab == 4) {
      this.modalTab.tab4 = true;
    }
  }
}
