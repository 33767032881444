import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-esg',
  templateUrl: './about-esg.component.html',
  styleUrls: ['./about-esg.component.sass']
})
export class AboutEsgComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
