import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-about-social',
  templateUrl: './about-social.component.html',
  styleUrls: ['./about-social.component.css']
})
export class AboutSocialComponent implements OnInit {

  image = (this.langService.currLang() === 'en') ? 'assets/images/about/Sustainable/social_00_2021072001.jpg' : 'assets/images/about/Sustainable/social_00_2021072001.jpg';

  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
