import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-career-main',
  templateUrl: './career-main.component.html',
  styleUrls: ['./career-main.component.css']
})
export class CareerMainComponent implements OnInit {
  modalTab: any = {
    tab1: false,
    tab2: false,
    tab3: false
  };
  constructor() { }

  ngOnInit() {
    this.modalTab.tab1 = true;
  }

  selectTab(selectTab) {
    this.modalTab.tab1 = false;
    this.modalTab.tab2 = false;
    this.modalTab.tab3 = false;

    if (selectTab === 1) {
      this.modalTab.tab1 = true;
    } else if (selectTab === 2) {
      this.modalTab.tab2 = true;
    } else if (selectTab === 3) {
      this.modalTab.tab3 = true;
    }
  }

}
