import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LangService } from '../_service/lang.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  isCollapsed = true;
  selectLang: string;
  isCollapsedIR = true;
  items: string[] = [
    'TH',
    'EN'
  ];
  check: boolean;
  constructor(private router: Router, private langService: LangService) {

    if (this.langService.currLang() === '') {
      langService.changeLang('th');
    } else {
      langService.changeLang(this.langService.currLang());
    }

  }
  page: string;
  ngOnInit() {
    
    this.selectLang = this.langService.currLang();
  }

  goto(page) {
    if (page !== '') {
      this.router.navigate(['/' + page]).then(resp => {
        this.page = page;
        //window.location.reload();
      });


    } else {
      this.router.navigate(['/']).then(resp => {
        this.page = 'index';
        // window.location.reload();
      });

    }
    this.check = false;


  }

  setLang(lang) {
    this.langService.changeLang(lang);
    this.selectLang = lang; 
    window.location.reload();
  }
}
