import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/_service/lang.service';

@Component({
  selector: 'app-ir-policies',
  templateUrl: './ir-policies.component.html',
  styleUrls: ['./ir-policies.component.css']
})
export class IrPoliciesComponent implements OnInit {

  modelDoc: any[] = [{
    titleTH: 'นโยบายการกำกับดูแลกิจการ',
    titleEN: 'Corporate Governance Policy',
    file: 'assets/doc/IR/CG_Policy.pdf'
  }];
  constructor(public langService: LangService) { }

  ngOnInit() {
  }

}
