import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  animateChild,
} from "@angular/animations";
import { Component, OnInit } from "@angular/core";
import { OrderPipe } from "ngx-order-pipe";
import sampleData from "../../../assets/data/projects.json";

@Component({
  selector: "app-hotels",
  templateUrl: "./hotels.component.html",
  animations: [
    trigger("items", [
      transition(":enter", [
        style({ transform: "scale(0.5)", opacity: 0 }), // initial
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({ transform: "scale(1)", opacity: 1 })
        ), // final
      ]),
      transition(":leave", [
        style({ transform: "scale(1)", opacity: 1, height: "*" }),
        animate(
          "1s cubic-bezier(.8, -0.6, 0.2, 1.5)",
          style({
            transform: "scale(0.5)",
            opacity: 0,
            height: "0px",
            margin: "0px",
          })
        ),
      ]),
    ]),
    trigger("list", [
      transition(":enter", [query("@items", stagger(300, animateChild()))]),
    ]),
  ],
  styleUrls: ["./hotels.component.css"],
})
export class HotelsComponent implements OnInit {
  modelBtnFilter: any = {
    filter: "",
    type: "",
  };

  modelProjectsFiltter: any[] = [];

  modelProjects: any[] = sampleData;

  constructor(public orderPipe: OrderPipe) {
    this.modelProjectsFiltter = orderPipe.transform(
      this.modelProjects,
      "order"
    );
  }

  ngOnInit() {
    //this.modelBtnFilter.filter = 'Z';
    this.onfilter("HO");
    // this.modelProjectsFiltter = this.modelProjects;
  }

  onfilter(filter) {
    this.modelProjectsFiltter = this.modelProjects.filter((s) => {
      return s.type === filter;
    });

    this.modelProjectsFiltter = this.orderPipe.transform(
      this.modelProjectsFiltter,
      "order"
    );
  }
}
